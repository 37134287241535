// @flow
import React, { useCallback } from 'react';
import {v4 as uuid4} from "uuid";
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Image,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalBody,
  // FormControl,
  // useDisclosure,
  // Alert,
  // AlertIcon,
  Divider
} from '@chakra-ui/react';

import MobileChangeSlider from '../MobileChangeSlider';
import LabeledSwitch from '../LabeledSwitch';
import COLORS from '../colors';
import ActionButton from '../ActionButton';
import OverallRiskGauge from '../OverallRiskGauge';
import {
  getRiskColorByPercentile,
  getRiskLevelByPercentile
} from '../risk-colors';
import traitRanges from '../reports/trait-ranges.json';
import GaugeChangeDetails from '../GaugeChangeDetails';
import withCalculator from "./WithCalculator";
import LabeledSelection from "../LabeledSelection";
import type {BooleanAttr, NumericAttr, SelectionAttr} from "./calculator-attr";
import cautionImage from '../../images/caution-sign-blue.png';
import MobileCalculatorPatientAttributes from "./MobileCalculatorPatientAttributes";
import {getCadRiskPlotBands} from "../risk-gauge-utils";
import PropTypes from "prop-types";

type Props = {
  disease: string,
  sex: number,
  numericAttrs: Array<NumericAttr>,
  booleanAttrs: Array<BooleanAttr>,
  selectionAttrs: Array<SelectionAttr>,
  onResetAttributes: any,
  getSwitchColor: any,
  getSwitchText: any,
  gender: ?string,
  onGenderSelected: any,
  riskQuintile: number,
  riskPercentile: number,
  playingEnabled: boolean,
  relativeRisk: number,
  relativeRiskOddsRatio: number,
  current: number,
  expected: number,
  shortText: string,
  longText: string,
  summaryChangeLine: string,
  trendIcon: any,
  changeColor: string,
  currentPercentile: number,
  expectedPercentile: number,
  shortTextPercentile: string,
  longTextPercentile: string,
  summaryChangeLinePercentile: string,
  trendIconPercentile: any,
  changeColorPercentile: string,
  currentRisk: number,
  expectedRisk: number,
  shortTextRisk: string,
  longTextRisk: string,
  showRisk: boolean,
  showRelativeToPop: boolean,
  showRelativeToCurrent: boolean,
  riskGauge: Object,
  printing: boolean,
  infoTexts: Object,
  callbackGetColorByRisk: any,
  callbackGetRiskLevel: any,
  callbackGetRecommendations: any,
  showGender?: boolean,
  callbackGetRiskLabel?: any,
  headerTitle?: string,
  showChangeDetails?: boolean
};

function MobileCalculatorImpl(props: Props) {
  const refHeader = React.useRef();

 const {callbackGetRiskLabel} = props;
 const getRiskLabel = useCallback((current, expected, fixDigits, highLimit) => {
    if (callbackGetRiskLabel) return callbackGetRiskLabel(current, expected, fixDigits, highLimit);
    if (expected > highLimit) return `> ${highLimit}%`;
    return `${expected.toFixed(fixDigits)}%`;
  }, [callbackGetRiskLabel]);

  const getOverallRiskDiffLabel = useCallback((current, expected, fixDigits) => {
    const diff = expected - current;
    if (diff === 0) return `${(current + 1).toFixed(fixDigits)}%`;
    return `${expected.toFixed(fixDigits)}%`;
  }, []);

  const {riskGauge} = props;
  const getPlotBands = useCallback(()=>{
    if (riskGauge.callbackGetPlotBands) {
      return riskGauge.callbackGetPlotBands;
    }
    return getCadRiskPlotBands
  }, [riskGauge])

  function getOutsideAgeRange() {
    let isYoungerThanMin = false;
    let isOlderThanMax = false;
    for (let i = 0; i < props.numericAttrs.length; i += 1) {
      if (props.numericAttrs[i].name === "age") {
        isYoungerThanMin = props.numericAttrs[i].currentValue && props.numericAttrs[i].currentValue < traitRanges.age.lowLimit;
        isOlderThanMax = props.numericAttrs[i].currentValue && props.numericAttrs[i].currentValue > traitRanges.age.highLimit;
      }
    }
    return {isYoungerThanMin, isOlderThanMax};
  }

  let {isYoungerThanMin, isOlderThanMax} = getOutsideAgeRange();
  // const headerText = `Change risk factor values to check the effect on your relative risk to develop ${props.disease}`
  const recommendations = props.callbackGetRecommendations ? props.callbackGetRecommendations(
    props.currentPercentile,
    props.currentRisk
  ) : null;

  const shortTextPercentile = (!props.shortTextPercentile || props.shortTextPercentile.length === 0) ? "0%" : props.shortTextPercentile;

  const getIsSliderEnabled = useCallback((attr: Object) => {
    if (!attr.isAdjustable || attr.currentValue === null || attr.currentValue === undefined) return false;
    if (attr.isSliderEnabled.constructor === Array) return attr.isSliderEnabled[0];
    return attr.isSliderEnabled;
  }, []);

  return (
    <Box borderColor="gray.300" borderWidth={1} borderRadius="10px" mx="1%">
      <Box mx="1%" my="5px">
        <Flex
          my="5px"
          bg="transparent"
          align="center"
          borderWidth={1}
          borderRadius="10px"
          ref={refHeader}
        >
          <Text fontSize={13} fontWeight="semibold" ml="10px" my="10px">
            {props.headerTitle ?? "CLINICAL FACTORS EFFECT ON OVERALL RISK"}
          </Text>
          <Spacer />
          <Image src={cautionImage} h="24px" alt="" mx="20px" />
        </Flex>
        <Box mt="10px">
          {props.disease && <Text fontSize={13}>
            <i>
              Change risk factor values to check the effect on your relative
              risk to develop CAD
            </i>
          </Text>}
          <Stack spacing="5px" mt="10px" fontSize={13}>
            {props.numericAttrs.map(attr=>
              attr.isAdjustable ?
                <MobileChangeSlider
                  val={props[attr.name]}
                  minVal={attr.min}
                  maxVal={attr.max}
                  stepVal={attr.step}
                  resetVal={attr.resetValue}
                  leftLabel={attr.displayText}
                  callbackGetColor={attr.fGetColorByValue}
                  callbackRightLabel={attr.fGetDiffText}
                  callbackOnValueChange={attr.onChange}
                  callbackOnValueEndChange={attr.onChange}
                  enabled={getIsSliderEnabled(attr)}
                  attrName={attr.name}
                  popoverInfo={attr.name === 'age' && (isYoungerThanMin || isOlderThanMax) ? props.infoTexts.agePopInfoTexts : undefined}
                /> : null)
            }
            {props.booleanAttrs.map(attr=>
              attr.visible ? <Box key={uuid4()} mr={"20px"}>
                <LabeledSwitch
                  isChecked={props[attr.name]}
                  leftLabel={attr.displayText}
                  leftLabelWidth="200px"
                  callbackGetColor={attr.callbackGetColor ? attr.callbackGetColor : props.getSwitchColor}
                  callbackOnValueChange={attr.onChange}
                  callbackRightLabel={props.getSwitchText}
                  enabled={props.playingEnabled}
                  attrName={attr.name}
                />
              </Box> : null)
            }
            {props.selectionAttrs.map(attr=>
              <Box mt="10px" key={uuid4()} mr={"20px"}>
                <LabeledSelection
                  leftLabelWidth="200px"
                  currentValue={props[attr.name]}
                  keyNameOptions={attr.keyNameOptions}
                  leftLabel={attr.displayText}
                  optionWidth={"150px"}
                  callbackOnValueChange={attr.onChange}
                  enabled={props.playingEnabled}
                  attrName={attr.name}
                />
              </Box>)
            }
          </Stack>
          <Flex>
            <Spacer />
            <ActionButton
              width="100px"
              height="30px"
              onClick={props.onResetAttributes}
              px="10%"
              fontSize={13}
              fontWeight="normal"
              borderWidth={1}
              borderColor={COLORS.REPORT_TEXT}
              name="Reset"
            />
            <Spacer />
          </Flex>
        </Box>
        {!props.showRisk && <Box mb="20px" spacing="20px">
          <OverallRiskGauge
            callbackGetColor={getRiskColorByPercentile}
            callbackGetRiskLevel={getRiskLevelByPercentile}
            callbackGetOverallRiskLabel={getOverallRiskDiffLabel}
            currentPercentile={props.currentPercentile}
            expectedPercentile={props.expectedPercentile}
            showSubtitle={false}
          />
          {props.showChangeDetails && <GaugeChangeDetails
            headerLine="Estimated risk percentile change:"
            changeColor={props.shortTextPercentile ? props.changeColorPercentile : COLORS.REPORT_TEXT}
            changeLines={[]}
            trendIcon={props.trendIconPercentile}
            shortText={shortTextPercentile}
            summaryLine={recommendations ? null : props.summaryChangeLinePercentile}
            printing={props.printing}
          />}
        </Box>}
        {props.showRisk && <Box mb="20px" spacing="20px">
          <OverallRiskGauge
            callbackGetColor={props.callbackGetColorByRisk}
            callbackGetRiskLevel={props.callbackGetRiskLevel}
            callbackGetOverallRiskLabel={getRiskLabel}
            callbackGetPlotBands={getPlotBands()}
            currentPercentile={props.currentRisk}
            expectedPercentile={props.expectedRisk}
            showSubtitle={false}
            fixDigits={1}
            overallRiskTitle={props.riskGauge.title}
            callbackGetOuterPlotBands={props.riskGauge.callbackGetOuterPlotBands}
            lines={props.riskGauge.lines}
          />
          {props.showChangeDetails && <GaugeChangeDetails
            headerLine="Estimated risk change"
            changeColor={props.changeColorPercentile}
            changeLines={[]}
            trendIcon={props.trendIconPercentile}
            shortText={props.shortTextRisk}
            summaryLine={null}
            printing={props.printing}
          />}
        </Box>}
      </Box>
      <Divider mx={"2%"} my={"10px"} maxW={"90%"}/>
      <MobileCalculatorPatientAttributes
        title={"Patient attributes:"}
        sex={props.sex === 1}
        showGender={props.showGender}
        geneticRiskTrait={props.disease}
        geneticRiskLevel={getRiskLevelByPercentile(props.riskPercentile).toLowerCase()}
        numericAttrs={props.numericAttrs}
        booleanAttrs={props.booleanAttrs}
        selectionAttrs={props.selectionAttrs}
        labelPrefix=""
        showBorder={false}
      />
      <Flex mb="10px" minW="100%">
        {props.infoTexts.model && <Box mx="20px" mb="10px">
          {isYoungerThanMin && props.infoTexts.youngerParticipant && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {props.infoTexts.youngerParticipant}
            </i>
          </Text>)}
          {isOlderThanMax && props.infoTexts.olderParticipant && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {props.infoTexts.olderParticipant}
            </i>
          </Text>)}
        </Box>}
      </Flex>
    </Box>
  );
}

MobileCalculatorImpl.propTypes = {
  showGender: PropTypes.bool,
  callbackGetRiskLabel: PropTypes.any,
  headerTitle: PropTypes.string,
  showChangeDetails: PropTypes.bool
}

MobileCalculatorImpl.defaultProps = {
  showGender: true,
  callbackGetRiskLabel: undefined,
  headerTitle: undefined,
  showChangeDetails: true
}

export default withCalculator(MobileCalculatorImpl);
