// @flow
import React, {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  Flex,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import PilotCadRiskReport from './PilotCadRiskReport';
import PilotT2dRiskReport from './PilotT2dRiskReport';
import PilotBpRiskReport from './PilotBpRiskReport';
import PilotHypercholesterolRiskReport from './PilotHypercholesterolRiskReport';
import COLORS from '../common/colors';
import LinkButton from '../common/LinkButton';
import routes, {getPilotsOrClinics, isClinicsUrl} from '../main/routes';
import PrintButton from '../common/PrintButon';
import ShareButton from "../common/ShareButton";
import PilotShareWithPatient from "./PilotShareWithPatient";
import withPilotPatientVisit from "./withPilotPatientVisit";
import {
  assignUserToPatient,
  fetchPatientCardioRisks,
  updatePatientEmailAndPhone
} from "../api/capilots";
import PopupMessage from "../common/PopupMessage";
import {isConsumerUser} from "../auth/auth";
import ConsumerCadRiskReport from "../consumer/ConsumerCadRiskReport";
import ConsumerT2dRiskReport from "../consumer/ConsumerT2dRiskReport";
import {getPatientId} from "./capilots-utils";
import ConsumerBpRiskReport from "../consumer/ConsumerBpRiskReport";
import {useAuthenticator} from "@aws-amplify/ui-react";
import listImage from "../images/list.png";
import {v4 as uuid4} from "uuid";
import withMobile from "../common/mobile/withMobile";
import PilotObesityRiskReport from "./PilotObesityRiskReport";

type Props = {
  location: any,
  navigate: any,
  params: any,
  visit: Object,
  age: number,
  patientId: ?string,
  selectedTabName: ?string,
  isMobile: boolean,
  onReload: any,
  callbackObesityDataAvailable: any
};

function PilotsCardioReport(props: Props) {
  const {isMobile} = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { user } = useAuthenticator((context) => [context.user]);
  const consumersClinic = isConsumerUser(user) && props.location.pathname.includes("consumers");
  const consumerPatientUser = isConsumerUser(user) && props.location.pathname.includes("self");
  const consumerReportMode = consumersClinic || consumerPatientUser;
  
  const patientId = props.patientId ?? getPatientId(props.params, props.location);
  const myAttributesUrl = consumerPatientUser ? `${routes.APP}/self/${patientId}/attributes` : null;

  const reportRefCad = useRef(null);
  const reportRefT2d = useRef(null);
  const reportRefHtn = useRef(null);
  const reportRefChol = useRef(null);
  const reportRefObesity = useRef(null);

  const refByTabIndex = useMemo(()=> {
    return [
      reportRefCad,
      reportRefChol,
      reportRefHtn,
      reportRefT2d,
      reportRefObesity
    ];
  }, []);

  const {selectedTabName} = props;
  const getSelectedTabIndex = useCallback(() => {
    const tabName = selectedTabName;
    let selectedTabIndex = 0;
    if (tabName === 'hchol') {
      selectedTabIndex = 1;
    }
    if (tabName === 'htn') {
      selectedTabIndex = 2;
    }
    if (tabName === 't2d') {
      selectedTabIndex = 3;
    }
    if (tabName === 'obesity') {
      selectedTabIndex = 4;
    }
    return selectedTabIndex;
  }, [selectedTabName]);
  const [tabIndex, setTabIndex] = useState(0);
  const controlledTabIndex = getSelectedTabIndex();
  if (controlledTabIndex !== tabIndex) setTabIndex(controlledTabIndex);

  const onTabChange = useCallback(index => {
    setTabIndex(index)
  }, []);

  const [printingState, setPrintingState] = React.useState({printing: false, resolve: undefined});
  const onPrintBeforeGetContent = () => {
     return new Promise((resolve, reject) => {
       if (loading) {
         reject();
         return;
       }
       console.log(`tabIndex is ${tabIndex}`)
       setPrintingState(prevPrintingState=>({printing: true, resolve}));
     })
  }

  const [cardioData, setCardioData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const {callbackObesityDataAvailable} = props;
  useEffect(() => {
    async function getCardioResults() {
      const data = await fetchPatientCardioRisks(patientId.toString());
      return data
    }
    getCardioResults().then((data)=> {
      setCardioData(data);
      setLoading(false);
      callbackObesityDataAvailable(data && data.data && data.data.obesity);
      console.log('loaded')
    });
  }, [patientId, callbackObesityDataAvailable]);

  useEffect(() => {
    const {resolve, ...otherState} = printingState;
    if (resolve) {
        setTimeout(()=> {
          resolve();
          setPrintingState({...otherState, resolve: undefined});
        }, 1000)
    }
  }, [printingState]);
  const onAfterPrint = () => { setPrintingState({printing: false, resolve: undefined}); }

  const currentLab = getPilotsOrClinics(props.location.pathname);
  const currentLabListUrl = currentLab ? `${routes.APP}/${currentLab}/${props.params.lab}/patient/list` : null;
  const isClinicsInUrl = isClinicsUrl(props.location.pathname);

  const propsVisit = props.visit;
  const onShare = useCallback(() => {
    if (!propsVisit) {
      setPopupMessage({
        show: true,
        color: COLORS.REPORT_TEXT,
        msg: "Page is still loading, try again when loading is finished.",
        title: "STILL LOADING"
      });
      return;
    }
    onOpen()
  }, [onOpen, propsVisit]);

  const onApplyShareWithPatient = useCallback(async (email, phone, username) => {
    try {
      await updatePatientEmailAndPhone(
          patientId,
          email,
          phone
      );
      await assignUserToPatient(patientId, username, consumersClinic, phone ?? null);
      setPopupMessage({
        show: true,
        color: COLORS.REPORT_TEXT,
        msg: "The account was created successfully. An invitation email was sent to the patient.",
        title: "PATIENT ACCOUNT CREATED"
      });
    } catch (e) {
      console.log("failed to update email/phone or assign user")
      console.log(e);
      setPopupMessage({
        show: true,
        color: COLORS.RED_STATUS,
        msg: "Could not create an account for the patient, check that the internet connection is on and try again.",
        title: "PATIENT ACCOUNT FAILED TO CREATE"
      });
    }
    onClose();
  }, [patientId, consumersClinic, onClose]);

  const [popupMessage, setPopupMessage] = React.useState({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT});
  const onClosePopup = useCallback(()=> setPopupMessage({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT}), [setPopupMessage])
  // const isSelfPage = isSelfUrl(props.location.pathname);

  const imageComp = useCallback(
    (image, size="18px") => <Image h={size} w={size} src={image} />,
    []
  );

  const isAthenaHealth = props.location.pathname.includes("athenahealth");
  const shareAllowed = !isMobile && ((currentLab && isClinicsInUrl) || isAthenaHealth);
  return (
    <Tabs
      variant="soft-rounded"
      my="10px"
      size="sm"
      index={tabIndex}
      onChange={onTabChange}
    >
      <Flex mx={isMobile ? 0 :"7%"} pl={0}>
        <TabList align={"center"} h={0} pl={0}>
          <Box pl={0}/>
          <Box pl={0}/>
          <Box pl={0}/>
          <Box pl={0}/>
          <Box pl={0}/>
        </TabList>
        <Spacer />
        {consumerPatientUser && <LinkButton
            fontWeight="bold"
            fontSize={16}
            to={myAttributesUrl}
            bg="transparent"
            color={COLORS.REPORT_TEXT}
            h="40px"
            ml="80px"
            mt="6px"
            leftIcon={imageComp(listImage)}
          >
            My attributes
          </LinkButton>
        }
        {shareAllowed && <Box h="40px" mt="6px" >
          <ShareButton
            title="share with patient"
            onShare={onShare}
            isTextDisplay={true}
          />
        </Box>}
        {!isMobile && currentLab && <LinkButton
          fontWeight="bold"
          fontSize={16}
          to={currentLabListUrl}
          bg="transparent"
          color={COLORS.REPORT_TEXT}
          h="40px"
          ml="10px"
          mt="6px"
        >
          Back to patient list
        </LinkButton>}
        {!isMobile && <Box h="40px" mt="6px" ml="10px">
          <PrintButton componentToPrint={refByTabIndex[tabIndex]} callbackOnBeforeGetContent={onPrintBeforeGetContent} callbackOnAfterPrint={onAfterPrint}/>
        </Box>}
      </Flex>
      <TabPanels mt={isMobile ? "-20px" : "-50px"}>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefCad} sx={{'@media print': { '@page': { size: 'A4 landscape !important;', width: '100%' }}}}>
            {!consumerReportMode && (
              <PilotCadRiskReport
                age={props.age}
                visit={props.visit}
                patientId={props.patientId}
                cad={cardioData ? cardioData.data.cad : null}
                pca={cardioData ? cardioData.data.pca : null}
                loading={loading}
                printing={printingState.printing && tabIndex === 0}
                isClinicianView={shareAllowed}
                onReload={props.onReload}
              />
            )}
            {consumerReportMode && (
              <ConsumerCadRiskReport
                age={props.age}
                visit={props.visit}
                cad={cardioData ? cardioData.data.cad : null}
                pca={cardioData ? cardioData.data.pca : null}
                loading={loading}
                printing={printingState.printing && tabIndex === 0}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefChol} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <PilotHypercholesterolRiskReport
              visit={props.visit}
              patientId={props.patientId}
              cholesterol={cardioData ? cardioData.data.hypercholesterol: null}
              loading={loading}
              printing={printingState.printing && tabIndex === 1}
              isClinicianView={shareAllowed}
            />
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefHtn} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            {!consumerReportMode && (
              <PilotBpRiskReport
                age={props.age}
                visit={props.visit}
                patientId={props.patientId}
                bp={cardioData ? cardioData.data.htn : null}
                loading={loading}
                printing={printingState.printing && tabIndex === 2}
                isClinicianView={shareAllowed}
              />
            )}
            {consumerReportMode && (
              <ConsumerBpRiskReport
                visit={props.visit}
                age={props.age}
                bp={cardioData ? cardioData.data.htn : null}
                loading={loading}
                printing={printingState.printing && tabIndex === 2}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefT2d} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            {!consumerReportMode && (
              <PilotT2dRiskReport
                patientId={props.patientId}
                age={props.age}
                visit={props.visit}
                t2d={cardioData ? cardioData.data.t2d : null}
                pca={cardioData ? cardioData.data.t2d : null}
                loading={loading}
                printing={printingState.printing && tabIndex === 3}
                isClinicianView={shareAllowed}
              />
            )}
            {consumerReportMode && (
              <ConsumerT2dRiskReport
                age={props.age}
                visit={props.visit}
                t2d={cardioData ? cardioData.data.t2d : null}
                pca={cardioData ? cardioData.data.t2d : null}
                loading={loading}
                printing={printingState.printing && tabIndex === 3}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefObesity} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <PilotObesityRiskReport
              visit={props.visit}
              patientId={props.patientId}
              obesity={cardioData && cardioData.data && cardioData.data.obesity ? cardioData.data.obesity: null}
              loading={loading}
              printing={printingState.printing && tabIndex === 4}
              isClinicianView={shareAllowed}
            />
          </Box>
        </TabPanel>
      </TabPanels>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotShareWithPatient
                patient={props.visit}
                onCancel={onClose}
                onApply={onApplyShareWithPatient}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <PopupMessage onClose={onClosePopup} isOpen={popupMessage.show} title={popupMessage.title} message={popupMessage.msg} parentRef={initialRef} messageColor={popupMessage.color}/>
    </Tabs>
  );
}

// $FlowFixMe
export default withNavigation(withPilotPatientVisit(withMobile(PilotsCardioReport)));
