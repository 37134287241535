// @flow
import React from 'react';

import COLORS from '../../common/colors';
import {
  getShortComparativeOddsRatioText,
  getSummaryOddsRatioText
} from '../reports/report-common';
import upRed from '../../images/up-red.png';
import downGreen from '../../images/down-green.png';
import { isNanOrNullOrUndefined } from '../../utils/numbers';
import type { RiskChange } from '../reports/risk-change';
import type {BooleanAttr, NumericAttr, SelectionAttr} from "./calculator-attr";

type Props = {
  sex: number,
  riskQuintile: number,
  riskPercentile: number,
  numericAttrs: Array<NumericAttr>,
  booleanAttrs: Array<BooleanAttr>,
  selectionAttrs: Array<SelectionAttr>,
  callbackRunLogReg: any,
  callbackInitialRisks: any,
  disease: string,
  showRisk: boolean,
  minRisk: number,
  maxRisk: number,
  callbackGetColorByRisk: any,
  callbackGetRiskLevel: any,
  callbackOnSavePatientAttrs: any,
  callbackTranslateOverallRiskValue: any,
  allowPatientAttrsEditing: boolean,
  allowPatientAttrsFirstMissingEditing: boolean
};

type State = {
  playingEnabled: boolean,
  sex: number,
  current: ?number,
  expected: ?number,
  shortText: string,
  longText: string,
  summaryChangeLine: string,
  trendIcon: any,
  changeColor: string,
  currentPercentile: ?number,
  expectedPercentile: ?number,
  shortTextPercentile: string,
  longTextPercentile: string,
  currentRisk: ?number,
  expectedRisk: ?number,
  shortTextRisk: string,
  longTextRisk: string,
  summaryChangeLinePercentile: string,
  trendIconPercentile: any,
  changeColorPercentile: string,
  changeColorRisk: string
};


export default function withCalculator(WrappedComponent: any) {
  return class WithCalculator extends React.Component<Props, State> {
    static getRiskText(quintile: number) {
      if (quintile === 0) return 'Low';
      if (quintile < 4) return 'Moderate';
      return 'High';
    }

    static getSwitchColor() {
      return 'red';
    }

    static getSwitchText(isChecked: boolean) {
      return isChecked ? 'YES' : 'NO';
    }

    static getLongDiffText(
      expected: ?number,
      current: number,
      trait: string,
      units: string,
      fixed: number
    ) {
      if (expected === current || !expected) return '';
      const diff = expected - current;
      const trend = diff > 0 ? 'increase' : 'decrease';
      return `\u2022 ${trait} ${trend} of ${Math.abs(diff).toFixed(
        fixed
      )} ${units}\n`;
    }

    static getSmokingStateChange(expected: boolean, current: boolean) {
      if (expected === current) return '';
      if (!expected) return '\u2022 quitting smoking';
      return '\u2022 starting smoking';
    }

    static getBinaryStateText(checked: boolean) {
      return checked ? 'yes' : 'no';
    }

    static calcRelativeRiskForCallback(oddsRatio: number) {
      if (oddsRatio < 1) return -((1 - oddsRatio) * 100);
      if (oddsRatio > 2) return 100;
      return (oddsRatio - 1) * 100;
    }

    initializeAttrs() {
      this.currentAttrs = {};
      this.resetAttrs = {};
      for (let i = 0; i < this.props.numericAttrs.length; i += 1) {
        const attribute = this.props.numericAttrs[i];
        if (attribute.fTranslateToDisplayValue && attribute.currentValue !== null) {
          this.currentAttrs[attribute.name] = attribute.currentValue;
          this.resetAttrs[attribute.name] = attribute.resetValue;
          this.currentAttrs[attribute.name] = attribute.fTranslateToDisplayValue(attribute.currentValue);
          this.resetAttrs[attribute.name] = attribute.fTranslateToDisplayValue(attribute.resetValue);
        } else {
          this.currentAttrs[attribute.name] = attribute.currentValue;
          this.resetAttrs[attribute.name] = attribute.resetValue;
        }
      }
      for (let i = 0; i < this.props.booleanAttrs.length; i += 1) {
        const attr = this.props.booleanAttrs[i];
        this.currentAttrs[attr.name] = !!attr.currentValue;
        this.resetAttrs[attr.name] = !!attr.resetValue;
      }
      for (let i = 0; i < this.props.selectionAttrs.length; i += 1) {
        const attr = this.props.selectionAttrs[i];
        this.currentAttrs[attr.name] = attr.currentValue;
        this.resetAttrs[attr.name] = attr.resetValue;
      }
      const numericAttrs = this.props.numericAttrs.map(a => {return {...a}})

      for (let i = 0; i < numericAttrs.length; i += 1) {
        const attr = numericAttrs[i];
        if (attr.isAdjustable) {
          attr.fGetDiffText = this.getNumericDiffText;
          attr.onChange = this.onSliderChange;
        } else {
          attr.onChange = this.onNumericAttrChange;
        }
        attr.resetValue = this.resetAttrs[attr.name]
      }
      const booleanAttrs = [...this.props.booleanAttrs];
      for (let i = 0; i < booleanAttrs.length; i += 1) {
        booleanAttrs[i].onChange = this.onBooleanAttrChange;
      }
      const selectionAttrs = [...this.props.selectionAttrs];
      for (let i = 0; i < selectionAttrs.length; i += 1) {
        selectionAttrs[i].onChange = this.onSelectionAttrChange;
      }
      this.numericAttrs = numericAttrs;
      this.booleanAttrs = booleanAttrs;
      this.selectionAttrs = selectionAttrs;
    }

    constructor(props: Props) {
      super(props);
      (this: any).updatePlayingEnabled = this.updatePlayingEnabled.bind(this);
      (this: any).calcExpectedRiskChange = this.calcExpectedRiskChange.bind(
        this
      );
      (this: any).onResetAttributes = this.onResetAttributes.bind(this);
      (this: any).getNumericDiffText = this.getNumericDiffText.bind(this);
      (this: any).getAttrByName = this.getAttrByName.bind(this);
      (this: any).getNumericAttrByName = this.getNumericAttrByName.bind(this);

      (this: any).onSliderChange = this.onSliderChange.bind(this);
      (this: any).onBooleanAttrChange = this.onBooleanAttrChange.bind(this);
      (this: any).onSelectionAttrChange = this.onSelectionAttrChange.bind(this);
      (this: any).onNumericAttrChange = this.onNumericAttrChange.bind(this);

      (this: any).getOptionName = this.getOptionName.bind(this);

      (this: any).getLongDiffTextForSelection = this.getLongDiffTextForSelection.bind(this);
      (this: any).callRunLogReg = this.callRunLogReg.bind(this);
      (this: any).onPatientAttrsChange = this.onPatientAttrsChange.bind(this);

      this.initializeAttrs();
      const playingEnabled = true;

      this.translateFromValueFuncs = {}
      for (let i = 0; i < props.numericAttrs.length; i += 1) {
        const attr = props.numericAttrs[i];
        this.translateFromValueFuncs[attr.name] = attr.fTranslateFromDisplayValue;
      }

      const initialRisks = this.callRunLogReg(
          this.props.sex ? this.props.sex : 0,
          this.resetAttrs
      )
      if (this.props.callbackInitialRisks) {
        this.props.callbackInitialRisks(initialRisks, this.resetAttrs);
      }
      const initState = {
        current: 0,
        expected: 0,
        shortText: '',
        longText: '',
        summaryChangeLine: '',
        trendIcon: undefined,
        changeColor: 'transparent',
        currentPercentile: initialRisks.percentile || 0,
        expectedPercentile: initialRisks.percentile || 0,
        shortTextPercentile: '',
        longTextPercentile: '',
        summaryChangeLinePercentile: '',
        currentRisk: initialRisks.risk || 0,
        expectedRisk: initialRisks.risk || 0,
        shortTextRisk: '',
        longTextRisk: '',
        trendIconPercentile: undefined,
        changeColorPercentile: 'transparent',
        changeColorRisk: 'transparent',
        sex: this.props.sex,
        playingEnabled: true
      };
      for (let i = 0; i < props.numericAttrs.length; i += 1) {
        const attr = props.numericAttrs[i];
        initState[attr.name] = this.currentAttrs[attr.name];
        initState[`${attr.name}SliderEnabled`] = playingEnabled && !!attr.defaultValue;
      }
      for (let i = 0; i < props.booleanAttrs.length; i += 1) {
        const attr = props.booleanAttrs[i];
        initState[attr.name] = attr.resetValue;
      }
      for (let i = 0; i < props.selectionAttrs.length; i += 1) {
        const attr = props.selectionAttrs[i];
        initState[attr.name] = attr.resetValue;
      }
      this.state = initState;
      this.recentDiffPercentile = undefined;
      this.recentRelativeRisk = 0;
      this.recentRelativeOddsRatio = 1;
    }

    onCalculatorChange() {
      const change = this.calcExpectedRiskChange();
      this.setState({
        current: change.relative.current,
        expected: change.relative.expected,
        shortText: change.relative.shortText,
        longText: change.relative.longText,
        summaryChangeLine: change.relative.summaryChangeLine,
        trendIcon: change.relative.trendIcon,
        changeColor: change.relative.changeColor,
        currentPercentile: change.overall.current,
        expectedPercentile: change.overall.expected,
        currentRisk: change.risk.current,
        expectedRisk: change.risk.expected,
        shortTextPercentile: change.overall.shortText,
        longTextPercentile: change.overall.longText,
        shortTextRisk: change.risk.shortText,
        longTextRisk: change.risk.longText,
        summaryChangeLinePercentile: change.overall.summaryChangeLine,
        trendIconPercentile: change.overall.trendIcon,
        changeColorPercentile: change.overall.changeColor,
        changeColorRisk: change.risk.changeColor
      });
      this.updatePlayingEnabled();
    }

    onSliderChange(newVal: number, attrName: string) {
      const newState = {}
      newState[attrName] = newVal;
      this.setState(newState, ()=>this.onCalculatorChange());
    }

    onBooleanAttrChange(newVal: boolean, attrName: string) {
      const newState = {}
      newState[attrName] = newVal;
      this.setState(newState, ()=>this.onCalculatorChange());
    }

    onSelectionAttrChange(newVal: boolean, attrName: string) {
      const newState = {}
      newState[attrName] = newVal;
      this.setState(newState, ()=>this.onCalculatorChange());
    }

    onNumericAttrChange(newVal: number, attrName: string) {
      const newState = {}
      newState[attrName] = newVal;
      this.setState(newState, ()=>this.onCalculatorChange());
    }

    onResetAttributes() {
      const newState = {}
      Object.keys(this.resetAttrs).forEach(attr => {
        newState[attr] = this.resetAttrs[attr]
      });
      this.setState(
        newState,
        this.onCalculatorChange
      );
    }

    getOverallRiskChangeDetails(expected: ?number, current: ?number, fTranslation: any) {
      if (!expected) {
        return WithCalculator.getNoChangeDetails();
      }
      const tcurrent = fTranslation ? fTranslation(current) : current;
      const texpected = fTranslation ? fTranslation(expected) : expected;
      const diff: number =
        texpected === undefined ||
        tcurrent === undefined ||
        texpected === null ||
        tcurrent === null
          ? 0
          : texpected - tcurrent;

      const trend: string = diff >= 0 ? 'increase' : 'decrease';
      let color = COLORS.REPORT_TEXT;
      let diffIcon: any = null;
      if (diff > 0) {
        color = COLORS.RED_STATUS;
        diffIcon = upRed;
      } else if (diff < 0) {
        color = COLORS.GREEN_STATUS;
        diffIcon = downGreen;
      }

      if (diff !== this.recentDiffPercentile) {
        // console.log(`diff percentile is ${diff}`);
        this.recentDiffPercentile = diff;
      }

      let shortRiskChange = `${diff.toFixed(1)}%`;
      if (this.props.maxRisk && diff > this.props.maxRisk) {
        shortRiskChange = `> ${this.props.maxRisk}`;
      }
      if (this.props.minRisk && diff < this.props.minRisk) {
        shortRiskChange = `< ${this.props.minRisk}`;
      }

      const longText = this.getTextOfChangedAttributes(diff);
      const summaryChangeLine = `${trend} your overall risk to develop coronary artery disease`;
      const riskChange: RiskChange = {
        current,
        expected,
        shortText: shortRiskChange,
        longText,
        summaryChangeLine,
        trendIcon: diffIcon,
        changeColor: color
      };
      return riskChange;
    }

    static getNoChangeDetails() {
      const riskChange: RiskChange = {
        current: 0,
        expected: 0,
        shortText: '',
        longText: '',
        summaryChangeLine: '',
        trendIcon: undefined,
        changeColor: 'transparent'
      };
      return riskChange;
    }

    getRelativeRiskChangeDetails(expected: ?number, current: ?number) {
      if (!expected) {
        return WithCalculator.getNoChangeDetails();
      }
      const diff: number =
        expected === undefined ||
        current === undefined ||
        expected === null ||
        current === null
          ? 0
          : expected - current;
      const trend: string = diff >= 0 ? 'increase' : 'decrease';
      const oddsRatio = expected / (current || 1);
      let shortRiskChange = getShortComparativeOddsRatioText(oddsRatio);

      const isSignificantDiff =
        shortRiskChange !== '-0%' && shortRiskChange !== '+0%';
      let summaryChangeLine = `${trend} your relative risk to develop ${this.props.disease} ${getSummaryOddsRatioText(
        oddsRatio
      )}`;
      let color = COLORS.REPORT_TEXT;

      if (isSignificantDiff) {
        color = diff > 0 ? COLORS.RED_STATUS : COLORS.GREEN_STATUS;
      } else {
        shortRiskChange = '~ 0 %';
        summaryChangeLine =
          `have a minor effect on your relative risk to develop ${this.props.disease}`;
      }
      const longText = this.getTextOfChangedAttributes(diff);
      let diffIcon: any = null;
      if (isSignificantDiff && diff > 0) diffIcon = upRed;
      if (isSignificantDiff && diff < 0) diffIcon = downGreen;

      const relativeRisk = WithCalculator.calcRelativeRiskForCallback(
        oddsRatio
      );
      if (
        relativeRisk !== this.recentRelativeRisk ||
        oddsRatio !== this.recentRelativeOddsRatio
      ) {
        // console.log(`relative risk ${relativeRisk}`);
        this.recentRelativeRisk = relativeRisk;
        this.recentRelativeOddsRatio = oddsRatio;
      }
      const riskChange: RiskChange = {
        current,
        expected,
        shortText: shortRiskChange,
        longText,
        summaryChangeLine,
        trendIcon: diffIcon,
        changeColor: color
      };
      return riskChange;
    }

    getTextOfChangedAttributes(diff: number) {
      let longText = '';
      if (diff !== 0) {
        longText = 'Changes to the following attributes:\n';
        for (let i = 0; i < this.props.numericAttrs.length; i += 1) {
          const attr = this.props.numericAttrs[i];
          longText += WithCalculator.getLongDiffText(
            this.state[attr.name],
            this.resetAttrs[attr.name],
            attr.name,
            attr.units,
            attr.fixDigits
          );
        }
        for (let i = 0; i < this.props.booleanAttrs.length; i += 1) {
          const attr = this.props.booleanAttrs[i];
          longText += WithCalculator.getLongDiffTextForBinary(
              this.state[attr.name],
              this.currentAttrs[attr.name],
              attr
          );
        }
        for (let i = 0; i < this.props.selectionAttrs.length; i += 1) {
          const attr = this.props.selectionAttrs[i];
          longText += this.getLongDiffTextForSelection(
              this.state[attr.name],
              this.currentAttrs[attr.name],
              attr
          );
        }
      }
      return longText;
    }

    static getLongDiffTextForBinary(
      expected: ?boolean,
      current: ?boolean,
      attr: BooleanAttr
    ) {
      if (expected === undefined || expected === null || Number.isNaN(expected)) return '';
      if (current === undefined || current === null || Number.isNaN(current)) return '';
      if (expected === current) return '';
      return `\u2022 changing ${attr.displayText} from ${this.getBinaryStateText(
        current
      )} to ${this.getBinaryStateText(expected)}\n`;
    }

    getOptionName(trait: string, key: string, keyNameOptions: Object) {
      let options = null;
      for (let i=0; i<this.props.selectionAttrs.length; i+=1) {
        if (this.props.selectionAttrs[i].name === trait) {
          options = this.props.selectionAttrs[i].keyNameOptions;
          break;
        }
      }
      if (!options) return '';
      return options[key];
    }

    getLongDiffTextForSelection(
      expected: number,
      current: number,
      attr: SelectionAttr
    ) {
      if (Number(expected) === Number(current)) return '';
      return `\u2022 changing ${attr.displayText} to ${this.getOptionName(attr.name, expected.toString())}\n`;
    }

    getNumericAttrByName(attrName: string) {
      for (let i = 0; i < this.props.numericAttrs.length; i += 1) {
        if (this.props.numericAttrs[i].name === attrName) return this.props.numericAttrs[i];
      }
      return null;
    }

    getAttrByName(attrName: string) {
      for (let i = 0; i < this.props.numericAttrs.length; i += 1) {
        if (this.props.numericAttrs[i].name === attrName) return this.props.numericAttrs[i];
      }

      for (let i = 0; i < this.props.booleanAttrs.length; i += 1) {
        if (this.props.booleanAttrs[i].name === attrName) return this.props.booleanAttrs[i];
      }

      for (let i = 0; i < this.props.selectionAttrs.length; i += 1) {
        if (this.props.selectionAttrs[i].name === attrName) return this.props.selectionAttrs[i];
      }
      return null;
    }

    getNumericDiffText(value: number, attrName: string) {
      if (value === this.resetAttrs[attrName]) return 'current';
      const diff: number = value - this.resetAttrs[attrName];
      const sign: string = diff > 0 ? '+' : '-';
      const attr = this.getNumericAttrByName(attrName);
      if (attr)
        return `${sign} ${Math.abs(diff).toFixed(attr.fixDigits)} ${attr.units}`;
      return '';
    }

    updatePlayingEnabled() {
      const newState = {}
      for (let i = 0; i < this.props.numericAttrs.length; i += 1) {
        const attr = this.props.numericAttrs[i]
        if (attr.isAdjustable) {
          newState[`${attr.name}SliderEnabled`] = !isNanOrNullOrUndefined(this.currentAttrs[attr.name]);
        }
      }
      newState['playingEnabled'] = newState['ageSliderEnabled'];
      this.setState(newState);
    }

    recentDiffPercentile: ?number;
    recentRelativeRisk: number;
    recentRelativeOddsRatio: number;
    currentAttrs: Object;
    resetAttrs: Object;
    translateFromValueFuncs: Object;
    numericAttrs: Array<NumericAttr>;
    booleanAttrs: Array<BooleanAttr>;
    selectionAttrs: Array<SelectionAttr>;

    callRunLogReg(sex: number, attrs: Object) {
      const callAttrs = {}
      Object.keys(attrs).forEach(attr => {
        if (this.translateFromValueFuncs[attr] && attrs[attr] !== null) {
          callAttrs[attr] = this.translateFromValueFuncs[attr](attrs[attr]);
        } else {
          callAttrs[attr] = attrs[attr];
        }
      })
      const res = this.props.callbackRunLogReg(
          sex,
          callAttrs
      )
      return res
    }

    calcExpectedRiskChange() {
      const current = this.callRunLogReg(this.state.sex, this.resetAttrs)
      const expected = this.callRunLogReg(this.state.sex, this.state);
      return {
        relative: this.getRelativeRiskChangeDetails(
          expected ? expected.odds : undefined,
          current ? current.odds : undefined
        ),
        overall: this.getOverallRiskChangeDetails(
          expected ? expected.percentile : undefined,
          current ? current.percentile : undefined,
          this.props.callbackTranslateOverallRiskValue
        ),
        risk: this.getOverallRiskChangeDetails(
          expected ? expected.risk : undefined,
          current ? current.risk : undefined,
        )
      };
    }

    onPatientAttrsChange(attrs: Object) {
      if (this.props.callbackOnSavePatientAttrs) {
        this.props.callbackOnSavePatientAttrs(attrs);
      }
      Object.keys(attrs).forEach(attr => {
        if (attr in this.resetAttrs) this.resetAttrs[attr] = attrs[attr];
        });
      this.onResetAttributes();
      const initialRisks = this.callRunLogReg(
          this.props.sex ? this.props.sex : 0,
          this.resetAttrs
      )
      if (this.props.callbackInitialRisks) {
        this.props.callbackInitialRisks(initialRisks, this.resetAttrs);
      }
    }

    render() {
      const stateAttrs = {};
      Object.keys(this.resetAttrs).forEach(attrName => {
        stateAttrs[attrName] = this.state[attrName]
      });
      return (
        <WrappedComponent
          {...this.props}
          onResetAttributes={this.onResetAttributes}
          getSwitchColor={WithCalculator.getSwitchColor}
          getSwitchText={WithCalculator.getSwitchText}
          relativeRisk={this.recentRelativeRisk}
          relativeRiskOddsRatio={this.recentRelativeOddsRatio}
          current={this.state.current}
          expected={this.state.expected}
          shortText={this.state.shortText}
          longText={this.state.longText}
          summaryChangeLine={this.state.summaryChangeLine}
          trendIcon={this.state.trendIcon}
          changeColor={this.state.changeColor}
          currentPercentile={this.state.currentPercentile}
          expectedPercentile={this.state.expectedPercentile}
          shortTextPercentile={this.state.shortTextPercentile}
          longTextPercentile={this.state.longTextPercentile}
          currentRisk={this.state.currentRisk}
          expectedRisk={this.state.expectedRisk}
          shortTextRisk={this.state.shortTextRisk}
          longTextRisk={this.state.longTextRisk}
          summaryChangeLinePercentile={this.state.summaryChangeLinePercentile}
          trendIconPercentile={this.state.trendIconPercentile}
          changeColorPercentile={this.state.changeColorPercentile}
          changeColorRisk={this.state.changeColorRisk}
          riskQuintile={this.props.riskQuintile}
          riskPercentile={this.props.riskPercentile}
          numericAttrs={this.numericAttrs}
          booleanAttrs={this.booleanAttrs}
          selectionAttrs={this.selectionAttrs}
          callbackOnSavePatientAttrs={this.onPatientAttrsChange}
          callbackOnCancelEditPatientAttr={this.onResetAttributes}
          {...stateAttrs}
        />
      );
    }
  };
}
